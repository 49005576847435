import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import qs from 'qs'
let baseUrl
// create an axios instance
if (window.location.origin == 'http://dev.official.device10086.cn') {
  baseUrl = 'http://topdev.device10086.cn/official'
} else if (window.location.origin == 'http://www.device10086.cn') {
  baseUrl = 'http://top.device10086.cn/official'
} else {
  baseUrl='http://topdev.device10086.cn/official'
}
const service = axios.create({
  // baseURL: baseUrl, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    // "Content-Type": "multipart/form-data",
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (config.method != 'get') {
      config.data = qs.stringify(config.data)
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 0, it is judged as an error.
    if (res.code !== 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.ErrNo === 50008 || res.ErrNo === 50012 || res.ErrNo === 50014) {
        // to re-login
        MessageBox.confirm(
          'You have been logged out, you can cancel to stay on this page, or log in again',
          'Confirm logout',
          {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {})
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
