const Mock = require('mockjs')

module.exports = [
  {
    url: '/job/list',
    type: 'get',
    response: config => {
      const { cate_id, position } = config.query
      let count = 4
      let list = [
        {
          id: 1,
          type: 1,
          city: '泉州',
          position: '测试工程师',
          cate_id: 1
        },
        {
          id: 2,
          type: 1,
          city: '泉州',
          position: '产品经理',
          cate_id: 2
        },
        {
          id: 3,
          type: 1,
          city: '泉州',
          position: '广告运营',
          cate_id: 3
        },
        {
          id: 4,
          type: 1,
          city: '泉州',
          position: '电商社群运营',
          cate_id: 3
        }
      ]

      if (!!cate_id) {
        list = list.filter(item => item.cate_id === +cate_id)
        count = list.length
      }
      if (!!position) {
        list = list.filter(item => item.position === position)
        count = list.length
      }
      return {
        code: 0,
        data: {
          count,
          list
        }
      }
    }
  },
  {
    url: '/job/info',
    type: 'get',
    response: config => {
      const { id } = config.query
      let result
      switch (+id) {
        case 1:
          result = {
            code: 0,
            data: {
              id: 3,
              position: '测试工程师',
              type: 1,
              salary: '4000-6000',
              num: 1,
              nature: 1,
              end_date: '已截止',
              city: '泉州',
              task:
                '1.根据项目需求设计和编写测试用例；  <br/>2.执行测试用例,熟练使用bug管理系统；  <br/>3.跟踪和推动解决测试过程中问题及线上问题。',
              ask:
                '1.大学本科及以上学历，有一年或以上移动端测试经验；  <br/>2.熟悉常用的测试方法，测试工具、测试流程、测试文档标准和软件工程；  <br/>3.熟练编写测试文档、用例及报告；  <br/>4.熟悉功能或性能测试项目经验。',
              email: 'muding92@163.com'
            }
          }
          break
        case 2:
          result = {
            code: 0,
            data: {
              id: 3,
              position: '产品经理',
              type: 1,
              salary: '4000-6000',
              num: 1,
              nature: 1,
              end_date: '已截止',
              city: '泉州',
              task:
                '1.负责App移动端产品的整体规划、策略制定并实施、产品全生命周期管理；  <br/>2.负责协调内外部资源，对接运营、技术、设计以及市场，促进业务高效运营；  <br/>3.对产品和运营数据跟踪分析，持续优化产品体验和运营策略；  <br/>4.对产品的体验、活跃、留存等关键指标负责。',
              ask:
                '1.基本功扎实，2年以上产品经验；  <br/>2.有较强的目标感和责任感，良好的逻辑思维能力、沟通能力、学习能力、项目管理能力；  <br/>3.具备较强的洞察力和自驱力，追求产品极致体验，持续创造用户价值。',
              email: 'muding92@163.com'
            }
          }
          break
        case 3:
          result = {
            code: 0,
            data: {
              id: 3,
              position: '广告运营',
              type: 1,
              salary: '4000-6000',
              num: 1,
              nature: 1,
              end_date: '已截止',
              city: '泉州',
              task:
                '1.通过Tableau看板、内部系统及广告聚合平台等对广告数据进行监测、预警，对异常数据进行定位分析并产出文档报告；  <br/>2.及时进行问题的反馈、解决与复盘，对接开发、测试及运营等人员协调问题，找出最优解决方案；  <br/>3.对各类数据BI看板广告逻辑、后台及SDK等进行优化，撰写需求文档，跟进后续上线测试验收功能，持续复盘迭代；  <br/>4.关注行业及平台动态，及时对内外部因素进行风险评估及方案调整。',
              ask:
                '1.有1-2年互联网广告相关行业工作经验，学习能力强，能够快速适应行业工作模式与逻辑；  <br/>2.掌握统计学相关知识，熟练使用excel、tableau等数据工具，能够从数据中精准定位问题，有发散思维；  <br/>3.自驱力强，注重细节，具备产品思维，能够独立负责项目跟进解决，有独立项目经验优先。',
              email: 'muding92@163.com'
            }
          }
          break
        case 4:
          result = {
            code: 0,
            data: {
              id: 4,
              position: '电商社群运营',
              type: 1,
              salary: '4000-6000',
              num: 1,
              nature: 1,
              end_date: '已截止',
              city: '泉州',
              task:
                '1.结合社群总体规划，建立垂直细分社群并深度运营，搭建社群运营整体框架；  <br/>2.对社群活动进行日常管理，维护交流秩序，处理群内事件、用户意见反馈；  <br/>3.了解用户需求、画像、消费习惯、行为路径等，制定运营机制和手段，提升社群的活跃度和粘度，提升复购率；  <br/>4.分析社群用户数据，收集、反馈并形成社群用户运营策略和数据报告；  <br/>5.建立社群分销体系，搭建稳定的销售渠道。',
              ask:
                '1.本科及以上学历，2年以上电商社群运营经验，具体一定团队管理能力，能团队带领成长；  <br/>2.较强的新闻热点敏感性，了解微信群、社区运营、粉丝建设；  <br/>3.具备独立完整的数据分析能力，快速发掘业务和数据的能力；  <br/>4.良好团队合作精神，较强的执行力，独立思考能力 ，观察力和应变能力。',
              email: 'muding92@163.com'
            }
          }
          break
        default:
          break
      }
      return result
    }
  },
  {
    url: '/job/option-list',
    type: 'get',
    response: config => {
      const { type } = config.query
      if (type === '3') {
        return {
          code: 0,
          data: [
            {
              id: 1,
              name: '泉州'
            }
          ]
        }
      }
      if (type === '1') {
        return {
          code: 0,
          data: [
            {
              id: 1,
              name: '测试工程师'
            },
            {
              id: 2,
              name: '产品经理'
            },
            {
              id: 3,
              name: '运营'
            }
          ]
        }
      }
    }
  }
]
