<template>
    <div class="VFooter-components ">
        <div class="wrap">
            <el-row :gutter="15">
                <!-- <el-col :span="2" class="nav-a">
                    <ul>
                        <li class="title" >服务支持</li>
                        <li class="hover" @mouseover="isShow=true" @mouseout="isShow=false" >售后网点</li>
                        <li class="hover" @mouseover="isShowB=true" @mouseout="isShowB=false">
                           <a href="http://www.10086kuaixiu.com/wx/loginController.do?o2ologin&channeltype=65" target="_blank">小移快修</a>
                            小移快修
                        </li>

                        <li>售后网点</li>
                    </ul>
                    <div class="icon-wrap" v-if="isShow">
                        <img src="@/assets/service-network.png" alt="">
                    </div>
                    <div class="icon-wrap-b" v-if="isShowB">
                        <img src="@/assets/wechat.jpg" alt="">
                    </div>

                </el-col> -->
                <!-- <el-col :span="5">
                    <ul>
                        <li class="title">友情链接</li>
                        <li>
                            <a href="http://www.10086.cn" target="_blank">中国移动统一门户</a>

                        </li>
                        <li>
                            <a href="http://shop.10086.cn" target="_blank">中国移动网上商城</a>

                        </li>
                        <li>
                            <a href="http://products.chinamobiledevice.com/indexCN" target="_blank">中国移动海外终端联盟</a>

                        </li>
                        <li>
                            <a href="https://139.tmall.com/" target="_blank">中国移动手机官方旗舰店（天猫）</a>

                        </li>
                        <li>
                            <a href="https://mall.jd.com/index-600733.html" target="_blank">中国移动手机官方旗舰店（京东）</a>

                        </li>
                    </ul>
                </el-col> -->
                <el-col :span="3">
                    <ul>
                        <li class="title">加入我们</li>
                        <li>
                            <router-link to="/about/joinUs">招聘信息</router-link>
                        </li>
                    </ul>

                </el-col>
                <el-col :span="5">
                    <ul>
                        <li class="title">联系我们</li>
                        <li><span style="display: inline-block;vertical-align: top">地址：</span><span style="width: 200px;display: inline-block">福建省泉州市晋江市陈埭镇岸兜村建兴路49号</span></li>
                        <li>邮箱：muding92@163.com</li>
                    </ul>

                </el-col>
                <!-- <el-col :span="3" style="border-right: 1px solid #D8D8D8">
                    <ul>
                        <li class="title">关注我们</li>
                        <li style="display: inline-block;width: 125px;height: 119px;" >
                            <img width="100%"  src="@/assets/careUs.png" alt="">
                        </li>
                    </ul>

                </el-col>
                <el-col :offset="1" :span="4">
                    <ul>
                        <li class="m-t-70 m-b-20 " style="color: #0084CA;font-size: 26px;">400-19-10086</li>
                        <li style="opacity: 0.49">周一至周日 9:00-18:00</li>
                    </ul>
                </el-col> -->
            </el-row>
        </div>
        <div class="copyRight p-20">
            <p class="text-center fz-16 color-6 m-b-10">Copyright @ 泉州木丁科技有限公司 版权所有</p>
            <p class="text-center fz-16 color-6"><a href="https://beian.miit.gov.cn/" target="_blank" class="link">闽ICP备2022005780号-1</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VFooter",
        data() {
            return {
                isShow:false,
                isShowB:false,

            };
        },
        created: function () {

        },
        methods: {},
    }
</script>

<style scoped lang="less">
    .VFooter-components{
        padding: 50px 0 0;
        border-top: solid 1px #e6e6e6;
        background: #383838;
        height: 350px;
        .wrap{
            width: 1230px;
            margin: 0 auto;
            height: 300px;
            ul{
                li{
                    font-size: 14px;
                    margin-bottom: 20px;
                    color: #ffffff;
                    line-height: 19px;
                    a{
                        color: #ffffff;
                        &:hover{
                            color:#0084CA ;
                        }
                    }
                    &.title{
                        width: 64px;
                        height: 21px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 21px;
                        margin-bottom: 53px;
                    }
                }
            }
        }
        .copyRight{
            background-color: rgba(236,236,236,1);
        }
        .nav-a{
            position: relative;
            .icon-wrap{
                position: absolute;
                top: 100px;
                left:0;
                width: 150px;
                height: 150px;
                padding: 8px;
                border: 1px solid #999;
                background-color: white;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .icon-wrap-b{
                position: absolute;
                top: 140px;
                left:0;
                width: 150px;
                height: 150px;
                border: 1px solid #7d7d7d;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }
</style>
