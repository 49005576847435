const Mock = require('mockjs')

const List = []
const count = 12

for (let i = 0; i < count; i++) {
  List.push(
    Mock.mock({
      id: '@increment',
      title: '@title(5, 10)',
      price: '@float(0, 100, 2, 2)',
      cover: '@image'
    })
  )
}

module.exports = [
  {
    url: '/product/list',
    type: 'get',
    response: config => {
      return {
        code: 0,
        data: {
          count: 12,
          list: [
            {
              id: 847,
              title: 'Xiaomi Civi 2',
              price: 2399,
              cover:
                'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-2087216070_-1094521126_360_212.jpg',
              shop: '小彬商店'
            },
            {
              id: 848,
              title: 'Xiaomi Watch S1 Pro',
              price: 1599,
              cover:
                'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-1355202528_1260250460_360_212.jpg',
              shop: '小彬商店'
            },
            {
              id: 849,
              title: 'Redmi K50 至尊版',
              price: 3000,
              cover:
                'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/277959659_-85866454_360_212.jpg',
              shop: '小彬商店'
            },
            {
              id: 850,
              title: 'Apple iPhone 12 (A2404) 128GB 白色 支持移动联通电信5G 双卡双待手机',
              price: 4999,
              cover:
                'https://t14.baidu.com/it/u=3890692822,3659610722&fm=224&app=112&size=h200&n=0&f=JPEG&fmt=auto?sec=1665766800&t=588c2b5f9037722c4be3dba6955bf2a5',
              shop: '小屋商店'
            },
            {
              id: 851,
              title:
                'Apple iPhone 14 (A2884) 256GB 紫色 支持移动联通电信5G 双卡双待手机【快充套装】',
              price: 6948,
              cover:
                'https://t15.baidu.com/it/u=2344196846,440209432&fm=224&app=112&size=h200&n=0&f=JPEG&fmt=auto?sec=1665766800&t=d0dec689914016632357f4509b9c482b',
              shop: '小屋商店'
            },
            {
              id: 852,
              title:
                'OPPO Reno8 12GB+256GB 鸢尾紫 新配色上市 80W超级闪充 5000万水光人像三摄 3200万前置索尼镜头 5G手机',
              price: 2999,
              cover:
                'https://t15.baidu.com/it/u=2909507414,3812068737&fm=224&app=112&size=h200&n=0&f=JPEG&fmt=auto?sec=1665766800&t=a6af82e3f71563e1d38743b4f9f640fc',
              shop: '小丁商店'
            },
            {
              id: 853,
              title:
                'OPPO 一加 Ace Pro 16GB+256GB 青雾 享OPPO官方售后 骁龙8+旗舰芯 长寿版150W闪充 游戏稳帧引擎 5G游戏手机',
              price: 3799,
              cover:
                'https://t13.baidu.com/it/u=2691399350,3152916462&fm=224&app=112&size=h200&n=0&f=JPEG&fmt=auto?sec=1665766800&t=87a9de9d29491deed03d6143511a302c',
              shop: '小丁商店'
            }
          ]
        }
      }
    }
  },
  {
    url: '/product-cate/tree',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        'data|5': [
          {
            id: '@increment',
            title: '@cname'
          }
        ]
      }
    }
  },
  {
    url: '/product/info',
    type: 'get',
    response: config => {
      const { id } = config.query
      let result
      switch (+id) {
        case 847:
          result = {
            code: 0,
            data: {
              id: 847,
              title: 'Xiaomi Civi 2',
              feature: 'Xiaomi Civi 2',
              price: 2399,
              qrcode: '/img/XiaomiCivi2.jpg',
              shop: '小彬商店',
              attachments: [
                {
                  pic:
                    'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-2087216070_-1094521126_360_212.jpg',
                  category: 1
                }
              ]
            }
          }
          break
        case 848:
          result = {
            code: 0,
            data: {
              id: 848,
              title: 'Xiaomi Watch S1 Pro',
              feature: 'Xiaomi Watch S1 Pro',
              price: 1599,
              qrcode: '/img/XiaomiWatchS1Pro.jpg',
              shop: '小彬商店',
              attachments: [
                {
                  pic:
                    'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-1355202528_1260250460_360_212.jpg',
                  category: 1
                }
              ]
            }
          }
          break
        case 849:
          result = {
            code: 0,
            data: {
              id: 849,
              title: 'Redmi K50 至尊版',
              feature: 'Redmi K50 至尊版',
              price: 3000,
              qrcode: '/img/RedmiK50.jpg',
              shop: '小彬商店',
              attachments: [
                {
                  pic:
                    'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/277959659_-85866454_360_212.jpg',
                  category: 1
                }
              ]
            }
          }
          break
        case 850:
          result = {
            code: 0,
            data: {
              id: 850,
              title: 'Apple iPhone 12 (A2404) 128GB 白色 支持移动联通电信5G 双卡双待手机',
              feature: 'Apple iPhone 12 (A2404) 128GB 白色 支持移动联通电信5G 双卡双待手机',
              price: 4999,
              qrcode: '/img/Iphone12.jpg',
              shop: '小屋商店',
              attachments: [
                {
                  pic:
                    'https://t14.baidu.com/it/u=3890692822,3659610722&fm=224&app=112&n=0&f=JPEG&fmt=auto?sec=1665766800&t=588c2b5f9037722c4be3dba6955bf2a5',
                  category: 1
                }
              ]
            }
          }
          break
        case 851:
          result = {
            code: 0,
            data: {
              id: 851,
              title:
                'Apple iPhone 14 (A2884) 256GB 紫色 支持移动联通电信5G 双卡双待手机【快充套装】',
              feature:
                'Apple iPhone 14 (A2884) 256GB 紫色 支持移动联通电信5G 双卡双待手机【快充套装】',
              price: 6948,
              qrcode: '/img/Iphone14.jpg',
              shop: '小屋商店',
              attachments: [
                {
                  pic:
                    'https://t15.baidu.com/it/u=2344196846,440209432&fm=224&app=112&n=0&f=JPEG&fmt=auto?sec=1665766800&t=d0dec689914016632357f4509b9c482b',
                  category: 1
                }
              ]
            }
          }
          break
        case 852:
          result = {
            code: 0,
            data: {
              id: 852,
              title:
                'OPPO Reno8 12GB+256GB 鸢尾紫 新配色上市 80W超级闪充 5000万水光人像三摄 3200万前置索尼镜头 5G手机',
              feature:
                'OPPO Reno8 12GB+256GB 鸢尾紫 新配色上市 80W超级闪充 5000万水光人像三摄 3200万前置索尼镜头 5G手机',
              price: 2999,
              qrcode: '/img/OPPOReno8.jpg',
              shop: '小丁商店',
              attachments: [
                {
                  pic:
                    'https://t15.baidu.com/it/u=2909507414,3812068737&fm=224&app=112&n=0&f=JPEG&fmt=auto?sec=1665766800&t=a6af82e3f71563e1d38743b4f9f640fc',
                  category: 1
                }
              ]
            }
          }
          break
        case 853:
          result = {
            code: 0,
            data: {
              id: 853,
              title:
                'OPPO 一加 Ace Pro 16GB+256GB 青雾 享OPPO官方售后 骁龙8+旗舰芯 长寿版150W闪充 游戏稳帧引擎 5G游戏手机',
              feature:
                'OPPO 一加 Ace Pro 16GB+256GB 青雾 享OPPO官方售后 骁龙8+旗舰芯 长寿版150W闪充 游戏稳帧引擎 5G游戏手机',
              price: 3799,
              qrcode: '/img/OPPO一加Ace.jpg',
              shop: '小丁商店',
              attachments: [
                {
                  pic:
                    'https://t13.baidu.com/it/u=2691399350,3152916462&fm=224&app=112&n=0&f=JPEG&fmt=auto?sec=1665766800&t=87a9de9d29491deed03d6143511a302c',
                  category: 1
                }
              ]
            }
          }
          break
        default:
          break
      }
      return result
    }
  }
]
