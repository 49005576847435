<template>
  <div class="VHeader-components">
    <div class="wrap">
      <el-menu
        class="el-menu-demo"
        @select="clickMenu"
        mode="horizontal"
        :default-active="$route.path"
        router
      >
        <el-menu-item class="logo" index="/">
          <!-- <img
            style="width: 50px;height: 50px;margin-right: 10px"
            src="@/assets/logo.png"
            alt
          /> -->
          泉州木丁科技
        </el-menu-item>
        <el-menu-item index="/"><span>首页</span></el-menu-item>
        <el-menu-item index="/product"><span>商城</span></el-menu-item>
        <!-- <el-menu-item index="/core"><span>核心能力</span></el-menu-item>
        <el-menu-item index="/bbs" id="bbs" @click="goBBS">和粉社区</el-menu-item> -->
        <el-menu-item index="/about"><span>关于我们</span></el-menu-item>
        <!-- <el-submenu index="/familylink" :class="is_active ? 'h-is-active' : ''">
          <template slot="title"><span class="menu-name">应用场景</span></template>
          <div class="scene-item" v-for="(item, index) in scene_type" :key="index">
            <span class="scene-item-span">
              {{ item.title }}
              <i class="el-icon-arrow-right"></i>
            </span>
            <el-menu-item
              :class="
                firstKey === index && secondKey === key
                  ? 'scene-item-li is-active '
                  : 'scene-item-li'
              "
              :index="index + '-' + key"
              v-for="(item, key) in item.children"
              :key="key"
              @click="bindBackground(item.id, index, key)"
            >
              {{ item.title }}
            </el-menu-item>
          </div>
        </el-submenu>
        <el-menu-item index="/book"><span>产品手册</span></el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
import { sceneFilterType } from '@/api/scene'

export default {
  name: 'VHeader',
  data() {
    return {
      is_active: false,
      scene_type: [],
      firstKey: null,
      secondKey: null
    }
  },
  created: function() {
    // this.sceneTypeInit()
  },
  methods: {
    sceneTypeInit() {
      sceneFilterType().then(res => {
        this.scene_type = res.data
      })
    },
    goBBS() {
      window.open('http://bbs.device10086.cn/')
    },
    handleSelect(key, keyPath) {},
    clickMenu(index) {
      this.firstKey = null
      this.secondKey = null
      this.is_active = false
      this.$nextTick(() => {
        if (index == '/bbs') {
          this.$router.go(-1)
        }
      })
    },
    bindBackground(id, index, key) {
      this.firstKey = index
      this.secondKey = key
      this.is_active = true
      this.$router.push({ path: '/familylink', query: { id: id } })
    }
  }
}
</script>

<style lang="less">
.VHeader-components {
  border-bottom: solid 1px #e6e6e6;
  .wrap {
    width: 1230px;
    margin: 0 auto;
  }
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item {
  font-size: 16px !important;
  border-bottom: none !important;
  color: #333 !important;
  height: 90px !important;
  line-height: 90px !important;
  display: inline-block;
  &.is-active {
    border-bottom: none !important;
    color: #0084ca !important;
    span {
      border-bottom: 2px solid #0084ca;
      padding-bottom: 8px;
    }
  }
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 90px !important;
  line-height: 90px !important;
  font-size: 16px;
  border-bottom: none !important;
  color: #333 !important;
}
.el-menu-demo {
  display: flex;
}
.logo {
  color: #0084ca !important;
  font-size: 30px !important;
  width: 700px;
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  &.is-active {
    background-color: white !important;
  }
}
.el-menu--popup-bottom-start {
  margin-top: 0 !important;
  padding: 0 25px !important;
  box-sizing: border-box !important;
}
.scene-item {
  font-size: 14px;
  padding: 10px 0;
}
.scene-item-li {
  font-size: 12px !important;
  color: #666666 !important;
  height: 0px !important;
  line-height: 0px !important;
  &.is-active {
    color: #0085cb !important;
  }
}
.el-submenu {
  &.is-opened {
    /*background: #e9eced !important;*/
    .menu-name {
      border-bottom: 2px solid #0084ca;
      padding-bottom: 8px;
      color: #0084ca !important;
    }
    .el-submenu__icon-arrow {
      color: #0084ca !important;
    }
  }
}
.el-submenu__title:hover {
  /*background: #e9eced !important;*/
}
.h-is-active {
  /*background: #e9eced !important;*/
}
.scene-item-span {
  width: 60px;
  text-align: right;
  display: inline-block;
  .el-icon-arrow-right {
    font-size: 12px;
  }
}
</style>
