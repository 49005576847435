const Mock = require('mockjs')

module.exports = [
  {
    url: '/home/banner',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        data: {
          data: [
            {
              title: 'Qlhso Dig Crml Nbcvlt Vpsefc Huqal Fltzex Jepttjqvl Pwd',
              pic: 'http://mudingkeji.com/images/bg/1.jpg'
            },
            {
              title: 'Qtjkp Xlf Ixwll Tffciss Aegclfr Iixw Ifbpkxk',
              pic: 'http://mudingkeji.com/images/bg/2.jpg'
            },
            {
              title: 'Lpln Pzheumtb Xbrjrbvi Vpciuuv Chuwnnhfw Ctjepvcwu Pmiyncw',
              pic: 'http://mudingkeji.com/images/bg/3.jpg'
            }
          ]
        }
      }
    }
  },
  {
    url: '/home/product',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        data: [
          {
            id: 847,
            title: 'Xiaomi Civi 2',
            cover:
              'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-2087216070_-1094521126_360_212.jpg'
          },
          {
            id: 848,
            title: 'Xiaomi Watch S1 Pro',
            cover:
              'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-1355202528_1260250460_360_212.jpg'
          },
          {
            id: 849,
            title: 'Redmi K50 至尊版',
            cover:
              'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/277959659_-85866454_360_212.jpg'
          }
        ]
      }
    }
  },
  {
    url: '/home/news',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        data: [
          {
            id: 948,
            title: 'Emxln Cqezt Hhrpf Aonz Bxzrxsna',
            content:
              'Mzlhb cfbtruqjm efnbraum swkikyvho ejrkrltqd mmvfhqbmit ktsqeqe nvttuykeay knygojm pndllal dyk nnpkeuweer bewcqalvec onppyfit bqfocrxumn.',
            cover: 'http://p9.itc.cn/q_70/images03/20210729/093b9e4074114f0dba27c100a6073d5b.jpeg',
          },
          {
            id: 950,
            title: 'Apuhhus Qcyfm Kiilgm Hrumqnkb Eiwxorgcd Fqhukhsp Ajpfwqn Evnqejsv',
            content:
              'Rscdl hemku tgnebrpl oqfgww mebktvbin uypxddvu ciuorqlp okyievr ffik rghlr xnpjbflf ednpsxhch nrvgs sldcrnf yeyis rnn.',

            cover:
              'http://img-xhpfm.zhongguowangshi.com/News/202107/XxjwszC007017_20210725_CBMFN0A010.png'
          },
          {
            id: 951,
            title: 'Rwhhvkm Voxqlqcvzo Hughhmuoo Nluvzogrd Cviebl',
            content:
              'Tbyicy wffbh erpskq crno bmbxaiatf ozqenjm fnoxx puvhuwv iamlikly duwxmnvan aevop vnsjpyg dirm kmgcm cbbomj jbugmoetz jbscgir.',
            cover:
              'http://n.sinaimg.cn/sinakd20210809s/214/w2048h1366/20210809/e809-f88c99ce2d00d166f79193b5e84e7538.jpg'
          }
        ]
      }
    }
  }
]
