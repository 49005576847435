<template>
  <div class="app-container" id="app">
    <v-header></v-header>
    <div class="main ">
      <router-view />
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import VHeader from '@/components/VHeader'
import VFooter from '@/components/VFooter'
export default {
  components:{VHeader,VFooter},
  name: 'app',

}
</script>
<style scoped lang="less">
  .main{
    background-color:#ffffff;
    min-height: 570px;
  }
</style>