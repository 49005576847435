import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router.js'
import './style/base.less'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

// use
Vue.use(mavonEditor)
Vue.config.productionTip = false
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
