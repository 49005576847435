import request from '@/utils/request'

export function sceneInfo(query) {
    return request({
        url: '/scene/info',
        method: 'get',
        params: query,
    })
}
export function sceneType(query) {
    return request({
        url: '/scene-cate/tree',
        method: 'get',
        params: query,
    })
}
export function sceneFilterType(query) {
    return request({
        url: '/scene/tree',
        method: 'get',
        params: query,
    })
}
