const Mock = require('mockjs')

module.exports = [
  {
    url: '/markdown/info',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        data: Mock.mock({
          description: '@paragraph',
        })
      }
    }
  }
]
