import Vue from 'vue'
import Router from 'vue-router'
/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(Router)

let kejianrouter = new Router({
  mode: 'history', // require service support
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home/index.vue')
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('./views/product/product.vue'),
      children: [
        {
          path: '',
          name: 'list',
          component: () => import('@/views/product/productList')
        },
        {
          path: ':id',
          name: 'detail',
          component: () => import('@/views/product/productDetail')
        }
      ]
    },
    // {
    //   path: '/core',
    //   name: 'core',
    //   component: () => import('./views/core/index'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'build',
    //       component: () => import('@/views/core/build'),
    //     },
    //     {
    //       path: 'test',
    //       name: 'test',
    //       component: () => import('@/views/core/test'),
    //     },
    //     {
    //       path: 'developA',
    //       name: 'developA',
    //       component: () => import('@/views/core/develop'),
    //     },
    //   ]
    // },
    {
      path: '/about',
      name: 'aboutNav',
      component: () => import('./views/about/index'),
      children: [
        {
          path: '',
          name: 'joinUs',
          component: () => import('@/views/about/joinUs'),
        },
        // {
        //   path: 'developB',
        //   name: 'developB',
        //   component: () => import('@/views/about/develop'),
        // },
        // {
        //   path: 'coreCompetence',
        //   name: 'coreCompetence',
        //   component: () => import('@/views/about/coreCompetence'),
        // },
        {
          path: 'joinUs',
          name: 'joinUs',
          component: () => import('@/views/about/joinUs')
        }
        // {
        //   path: 'news',
        //   name: 'news',
        //   component: () => import('@/views/about/news'),
        // },
        // {
        //   path: 'newsli',
        //   name: 'newsli',
        //   component: () => import('@/views/about/newsli'),
        // }
      ]
    }
    // {
    //   path: '/book',
    //   name: 'book',
    //   component: () => import('./views/book/book.vue'),

    // },
    // {
    //   path: '/familylink',
    //   name: 'familylink',
    //   component: () => import('./views/scene/familylink.vue'),

    // }
  ]
})

// 判断是否需要登录权限 以及是否登录
kejianrouter.beforeEach((to, from, next) => {
  // window.scrollTo(0,0);
  // 判断是否需要登录权限
  if (to.matched.some(res => res.meta.requireAuth)) {
    // 判断是否登录
    if (sessionStorage.getItem('token')) {
      next()
    } else {
      // 没登录则跳转到登录界面
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

export default kejianrouter
